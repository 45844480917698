<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />
         <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">                 
            <template v-for="(item, key) in list_check_box">                 
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">                            
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    <!-- Section Detail Start -->        

                    
                    <Conclusion v-if="key == 'detail_Conclusion' && item.show" /> 
                 
                    <div class="content-onglets"  v-else-if="key == 'detail_general_information' && item.show">
                        <div class="fix-table-xl">                                
                            <div class="box-toggle" >
                                <a  class="title title-tb"  @click="expiration_date_Show = !expiration_date_Show " style="cursor:pointer;">
                                    Expiration date 
                                    <em :class="expiration_date_Show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="expiration_date_Show">
                                    {{ DDMMMYY(data.peru['expiration_date']) }}
                                </section>
                            </div>
                        </div>
                    </div>

                    <!--1indication start-->
                    <Indication v-if="key == 'detail_indication' && item.show"/> 
                    <!--indication end-->
                    
                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->
                 
                    <!--Evidence in brief start-->     
                    <div class="content-onglets" v-else-if="key == 'detail_evidence_in_brief_original' && item.show">
                        <p v-html="data.peru['evidence_in_brief' +suffix_txt]"></p>
                    </div>
                    <!--Evidence in brief end-->
                    
                    <!--2background_ start-->
                 
                    <div class="content-onglets" v-else-if="key == 'detail_background_original' && item.show">
                        <p v-html="data.peru['background' +suffix_txt ]"></p>
                    </div>                  
                    <!--background_ end-->

                    <!--Efficacy start-->                  
                    <div class="content-onglets" v-else-if="key == 'detail_efficacy_eng' && item.show">
                        <p v-html="data.peru['efficacy' + suffix_txt_eng]"></p>
                    </div>
                    <!--Efficacy end-->

                    <!--Safety start-->                        
                    <div class="content-onglets" v-else-if="key == 'detail_safety_eng' && item.show">
                        <p v-html="data.peru['safety' + suffix_txt_eng]"></p>
                    </div>                    
                    <!--Safety end-->

                    <!--Cost-effectiveness start-->
                    <div class="content-onglets" v-else-if="key == 'detail_cost_effectiveness_eng' && item.show">
                        <p v-html="data.peru['cost_effectiveness' + suffix_txt_eng]"></p>
                    </div>                    
                    <!--Cost-effectiveness end-->

                    <!--Other start-->
                    <div class="content-onglets" v-else-if="key == 'detail_other_eng' && item.show">
                        <p v-html="data.peru['other' + suffix_txt_eng]"></p>
                    </div>
                    <!--Other end-->
                            
                    <!--- @todo Cost start--> 
                    <div class="content-onglets table"  v-else-if="key == 'detail_PeruPrice' && item.show">
                        <table class="comparateur2" v-if="data.peru['peru_price'].length > 0 || data.peru['same_price_as_comparators']">
                            <thead>
                                <tr>
                                    <th width="40%" >{{$t('spain.description_therapy')}}</th>      
                                    <th width="30%" >{{$t('spain.Price')}}</th> 
                                    <th width="30%" >{{$t('spain.Price incl')}}</th> 
                                </tr>
                            </thead>
                            <tbody>                                        
                                <tr v-for="(items,keys) in data.peru['peru_price'] " :key="'peru_price'+keys">                        
                                    <td>{{items['description_therapy']? items['description_therapy'] :  "-"}}</td>    
                                    <td>{{items['price'] ? items['price'] :  "-"}}</td> 
                                    <td>{{items['price_incl'] ?items['price_incl'] :  "-"}}</td> 
                                </tr>                                     
                                <tr v-if="data.peru['same_price_as_comparators']">
                                    <th width="40%">{{$t('spain.Same price as comparators')}}</th>
                                    <td width="60%" colspan="2">{{ data.peru['same_price_as_comparators'] }}</td>                        
                                </tr>     
                            </tbody>
                        </table>   
                        <table class="comparateur2" v-if="data.peru['peru_cost_treatment'].length > 0">
                            <thead>
                                <tr>
                                    <th width="40%" >{{$t('spain.description_therapy')}}</th>    
                                    <th width="30%" >{{$t('spain.Cost of treatment')}}</th>
                                    <th width="30%" >{{$t('spain.Cost of treatment incl')}}</th>
                                </tr>
                            </thead>
                            <tbody>                                        
                                <tr v-for="(items,keys)  in data.peru['peru_cost_treatment'] " :key="'peru_cost_treatment'+keys">                        
                                    <td>{{items['description_therapy'] ? items['description_therapy'] : "-" }}</td>
                                    <td>{{items['cost_of_treatment'] ?  items['cost_of_treatment'] : "-"}}</td>
                                    <td>{{items['cost_of_treatment_incl'] ? items['cost_of_treatment_incl']: "-"}}</td>
                                </tr>                                        
                            </tbody>
                        </table>                            
                    </div>
                        
                    <!--Cost end-->
                    
                    <!-- 'sub_detail/evaluation_economic.ctp'  -->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.peru.evaluation_economic" />

                     <!-- 'sub_detail/EconomicEvaluation.ctp' -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />

                    <!--BudgetImpact start-->
                    <BudgetImpact :data="data.peru['budget_impacts']" v-else-if="key == 'detail_BudgetImpact' && item.show" /> 
                    <!--BudgetImpact end-->   

                    <div class="content-onglets" v-else-if="key == 'detail_target_population_original' && item.show">
                        <p v-html="data.peru['target_population' + suffix_txt] ? data.peru['target_population' + suffix_txt] : '-'"></p>
                        <div class="fix-table-xl" v-if="data.peru['target_population_number']">                                
                                <div class="box-toggle">
                                    <a  class="title title-tb"  @click="target_population_number_Show = !target_population_number_Show " style="cursor:pointer;">
                                        {{$t('spain.Target population number') }}
                                        <em :class="target_population_number_Show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                    </a>
                                    <section class="" id="target_population_number"  v-if="target_population_number_Show">
                                       {{ data.peru['target_population_number'] }}       
                                    </section>
                                </div>
                        </div>                        
                    </div>

                    <!--Comparator start-->     
                    <div class="content-onglets" v-else-if="key == 'detail_comparator_original'  && item.show">
                        <p v-html="data.peru['comparator' + suffix_txt]  ? data.peru['comparator' + suffix_txt] : ''"></p>                         
                        <div class="fix-table-xl" v-if="data.peru['peru_comparator_name'].length > 0">                           
                            <div class="box-toggle">
                                <a  class="title title-tb"  @click="PeruComparatorName_Show = !PeruComparatorName_Show " style="cursor:pointer;">
                                    {{ $t('spain.Comparator name')}}
                                    <em :class="PeruComparatorName_Show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>   
                                </a>
                                <section class="" id="PeruComparatorName" v-if="PeruComparatorName_Show">
                                    <table class="comparateur2">
                                        <thead>
                                            <tr>
                                                <th width="100%" >{{ $t('spain.Comparator name')}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>                                                    
                                            <tr v-for="(items,keys) in data.peru['peru_comparator_name']" :key="'peru_comparator_name'+keys">
                                                <td>{{items['comparator_name'] ? items['comparator_name'] : "-" }}</td>     
                                            </tr>                                                     
                                        </tbody>
                                    </table>       
                                </section>
                            </div> 
                        </div> 
                    </div>   
                    <!--Comparator end-->
                
                    <!--Final Considerations start-->                        
                    <div class="content-onglets" v-else-if="key=='detail_final_consideration_eng'  && item.show">
                        <p v-html="data.peru['final_consideration' + suffix_txt_eng]"></p>
                    </div>
                    <!--Final Considerations end-->

                    <!-- key_documents -->
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>

                    <!--todo detail_EssaisClinique -->
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>



                </div>
            </template>

            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
			

        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue'
import Conclusion from '../conclusion.vue'
import BudgetImpact from '../budget_impact.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import TreatmentLine from '../treatment_line.vue'
import LinkAgency from '../LinkAgency.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import KeyDocument from '../key_document.vue'
import Indication from '../Indication.vue'
import { DDMMMYY, check_detail_conclusion, key_documents } from '../../../utils'

export default {
    name: 'peru',
    components : {
        LeftSection, 
        Conclusion,
        BudgetImpact,
        EvaluationEconomic,
        EconomicEvaluation,
        TreatmentLine,
        LinkAgency,
        EssaisClinique,
        KeyDocument,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_general_information : {'title' : 'general_information', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_indication : {'title' : 'spain.Indication under review', 'show' : true, 'enable' : false, 'orange_text':false},  
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_evidence_in_brief_original : {'title' : 'evidence_in_brief', 'show' : true, 'enable' : false, 'orange_text':false }, 
                detail_background_original :{'title':'spain.Background', 'show' : true, 'enable' : false, 'orange_text':false }, 
                detail_efficacy_eng  :{'title':'Efficacy', 'show' : true, 'enable' : false, 'orange_text':false }, 
                detail_safety_eng  :{'title':'Safety', 'show' : true, 'enable' : false, 'orange_text':false }, 
                detail_cost_effectiveness_eng :{'title':'spain.Cost-effectiveness', 'show' : true, 'enable' : false, 'orange_text':false }, 
                detail_other_eng :{'title':'Other', 'show' : true, 'enable' : false, 'orange_text':false }, 
                detail_PeruPrice  :{'title':'Cost', 'show' : true, 'enable' : false, 'orange_text':false }, 
                detail_EvaluationEconomic : {'title' : 'Economic evaluation' ,'show' : true, 'enable' : false, 'orange_text':true }, 
                detail_EconomicEvaluation : {'title':'Economic evaluation','show' : true, 'enable' : false, 'orange_text':true }, 
                detail_BudgetImpact : { 'title':'Budget impact', 'show' : true, 'enable' : false, 'orange_text':true }, 
                detail_target_population_original : { 'title':'spain.Target population', 'show' : true, 'enable' : false, 'orange_text':false },  
                detail_comparator_original : { 'title':'spain.Comparator', 'show' : true, 'enable' : false, 'orange_text':false }, 
                detail_final_consideration_eng :{ 'title':'Final Considerations', 'show' : true, 'enable' : false, 'orange_text':false }, 
                detail_clinical_trials : {'title':'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true }, 
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false }, 
            },
            suffix_txt :"",
            suffix_txt_eng : "",
            expiration_date_Show : false,
            sane_as_ma_Show : false,
            PeruComparatorName_Show : false,
            target_population_number_Show : false

        }
   
    },
    created(){
        this.suffix_txt = '_original'
        this.suffix_txt_eng = '_eng'   
       if (this.$i18n.locale == 'es'){
            this.suffix_txt = ''
            this.suffix_txt_eng = '_span'
        }

        // condition to show check box in left section [enable]
        if(this.data && this.data.peru){ 
            
            if(this.check_detail_conclusion(this.data, this.$i18n.locale) ||
                this.data.peru['limitation'] ||
                this.data.peru['rationale_and_commentary'+this.suffix_txt]) 
            { 
                    this.list_check_box['detail_Conclusion'].enable = true
            }
            if (this.data.peru['expiration_date']) {
                this.list_check_box['detail_general_information'].enable = true                    
            }
            if(this.data['indication_original'] || this.data['indication_en'] ) {
                this.list_check_box['detail_indication'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }
            if  (this.data.peru['evidence_in_brief'+this.suffix_txt]) {
                this.list_check_box['detail_evidence_in_brief_original'].enable = true
            }
            if (this.data.peru['background'+this.suffix_txt]) {
                this.list_check_box['detail_background_original'].enable = true
            }                       
            if (this.data.peru['efficacy'+this.suffix_txt_eng]) {
                this.list_check_box['detail_efficacy_eng'].enable = true
            }       
            if (this.data.peru['safety'+this.suffix_txt_eng]){
                this.list_check_box['detail_safety_eng'].enable = true
            }
            if (this.data.peru['cost_effectiveness'+this.suffix_txt_eng]){
                this.list_check_box['detail_cost_effectiveness_eng'].enable = true

            }
            if (this.data.peru['other'+this.suffix_txt_eng]){
                this.list_check_box['detail_other_eng'].enable = true
            }
            //@todo price
            if (this.data.peru['peru_price'].length > 0 || this.data.peru['peru_cost_treatment'].length > 0  ){
                this.list_check_box['detail_PeruPrice'].enable = true
            }
            if(this.data.peru['evaluation_economic'].length > 0 ) { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }
            
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false
            }
            
            if(this.data.peru['budget_impacts'].length > 0 ) { 
                this.list_check_box['detail_BudgetImpact'].enable = true
            }
            
            if (this.data.peru['target_population'+this.suffix_txt] || this.data.peru['target_population_number']) {
                this.list_check_box['detail_target_population_original'].enable = true
            }
            if (this.data.peru['comparator'+this.suffix_txt] || this.data.peru['peru_comparator_name'].length > 0 ) {
                this.list_check_box['detail_comparator_original'].enable = true                    
            }
            if (this.data.peru['final_consideration'+this.suffix_txt_eng]){
                this.list_check_box['detail_final_consideration_eng'].enable = true 
            }              
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            } 
            if(this.data.essais_clinique &&  this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }
        }

        
    },
    methods : {
        DDMMMYY,
        check_detail_conclusion,
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }        
    },
}
</script>

<style scoped>

a{
    color: #000; 
}

.box-toggle.icon-arrow-updown {
    font-size: 16px;
    right: -14px;
    color: #232426;
    margin-left:5px;
}
.box-toggle a:hover i {
    color: #ff6300;
}
.fa-angle-up {
    color : #ff6300;
}
</style>